import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import faviconApple from '../favicon_package_v0.16/apple-touch-icon.png';
import favicon32 from '../favicon_package_v0.16/favicon-32x32.png';
import favicon16 from '../favicon_package_v0.16/favicon-16x16.png';
import faviconSafari from '../favicon_package_v0.16/safari-pinned-tab.svg';

import Header from './header';
import Footer from './footer';

function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={(data) => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'AEP Claims Portal' },
              { name: 'keywords', content: 'damage, claims, aep, american electric power' },
            ]}
          >
            <link rel="apple-touch-icon" sizes="180x180" href={faviconApple} />
            <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
            <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
            <link rel="mask-icon" href={faviconSafari} color="#5bbad5" />

          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div
            style={{
              margin: '0 auto',
              maxWidth: '100%',
              padding: '0px 1.0875rem 1.45rem',
              paddingTop: 0,
            }}
          >
            {children}
          </div>
          <Footer />
        </>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
